import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { isAuthenticated } from 'src/services/auth'

function RouteWrapper({ component: Component, isPrivate = false, ...rest }) {
  const signed = isAuthenticated()

  if (!signed && isPrivate) return <Redirect to="/login" />
  if (signed && !isPrivate) return <Redirect to="/" />

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
