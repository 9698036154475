import React, { useEffect, useContext } from 'react'

import { FiAlertCircle, FiCheckCircle, FiInfo, FiAlertTriangle, FiXCircle } from 'react-icons/fi'

import { ToastContext } from 'src/hooks/toast'

import { Container } from './styles'

const toastIcons = {
  info: <FiInfo size={24} />,
  danger: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
  warning: <FiAlertTriangle size={24} />,
}

export function Toast({ message }) {
  const { removeToast } = useContext(ToastContext)

  useEffect(() => {
    const timer = setTimeout(() => removeToast(message.id), 5000)

    return () => clearTimeout(timer)
  }, [removeToast, message.id])

  return (
    <Container
      key={message.id}
      type={message.type}
      layout="position"
      initial={{ x: 364, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{
        x: 364,
        opacity: 0,
        transition: { type: 'spring', duration: 0.45 },
      }}
      transition={{ type: 'spring', duration: 0.6 }}
    >
      {toastIcons[message.type]}

      <div>
        <strong>{message.title}</strong>
        <p>{message.description}</p>
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>
  )
}
