import React from 'react'
import { Switch, Router } from 'react-router-dom'

import Route from './Route'
import history from 'src/utils/history'

// Containers
import DefaultLayout from 'src/components/DefaultLayout'

// Pages
import Login from '../views/pages/login/Login'
import Register from '../views/pages/register/Register'
import Page404 from '../views/pages/page404/Page404'
import Page500 from '../views/pages/page500/Page500'

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route exact path="/register" name="Register Page" component={Register} />
        <Route exact path="/404" name="Error 404" component={Page404} />
        <Route exact path="/500" name="Error 500" component={Page500} />
        <Route path="/" name="Home" component={DefaultLayout} isPrivate />
      </Switch>
    </Router>
  )
}

export default Routes
