import React, { Component } from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import client from './services/graphql'
import Routes from './routes'
import AppProvider from './hooks'
import './scss/style.scss'
import * as FramerMotion from 'framer-motion'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <FramerMotion.AnimateSharedLayout type="crossfade">
          <FramerMotion.AnimatePresence>
            <AppProvider>
              <BrowserRouter>
                <React.Suspense fallback={loading}>
                  <Routes />
                </React.Suspense>
              </BrowserRouter>
            </AppProvider>
          </FramerMotion.AnimatePresence>
        </FramerMotion.AnimateSharedLayout>
      </ApolloProvider>
    )
  }
}

export default App
