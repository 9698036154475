import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

import { ToastContainer } from 'src/components/ToastContainer'

export const ToastContext = createContext({})

export function ToastProvider({ children }) {
  const [messages, setMessages] = useState([])

  function addToast(message) {
    const id = uuid()

    const toast = { id, ...message }

    setMessages((prevState) => [...prevState, toast])
  }

  function removeToast(id) {
    setMessages((prevState) => prevState.filter((message) => message.id !== id))
  }

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
