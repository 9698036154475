import { gql } from '@apollo/react-hooks'

export const LIST_USERS = gql`
  query Users($filter: FilterOptions) {
    result: users(filter: $filter) {
      id
      name
      email
    }
  }
`
