import { gql } from '@apollo/react-hooks'

export const LIST_PARTNER = gql`
  query Partners($filter: FilterOptions!) {
    result: partners(filter: $filter) {
      id
      name
      cnpj
      email
      image
      phone
      address
    }
  }
`
